@use 'kravag' as *;

$kravag-link-hover-color: #e1001a;

body[theme='kravag-kkink'] {
  --font-family: Arial, sans-serif;
  --primary: #{$kravag-secondary};
  --secondary: #{$kravag-highlight};

  --kendo-border-radius-lg: 5px;
  --kendo-border-radius-md: 4px;
  --button-text-color: #{$kravag-primary};
  --button-solid-hover-bg-color: #{$kravag-highlight};
  --kendo-button-solid-secondary-active-hover-bg: #{$kravag-highlight};
  --kendo-button-solid-secondary-active-hover-border: #{$kravag-highlight};
  --highlighted-font-color: #{$kravag-highlight};

  --link-hover-color: #{$kravag-link-hover-color};

  .welcome {
    font-size: 28px;
    color: #{$kravag-secondary};
  }

  .k-button-text {
    color: #{$kravag-primary};
  }
  a:hover {
    color: var(--link-hover-color);
  }

  a,
  a:active {
    color: var(--primary);
  }
  a:hover,
  a:active {
    text-decoration: underline !important;
  }
}

// RuV color scheme

// Primary colors
$darkblue: #001957;
$sand: #fff2dc;
$white: #ffffff;

// Highlight colors
// action pressed
$cyan-1: #1da3b6;
// action default
$cyan-2: #00e6e6;
// action hover
$cyan-3: #00fafa;
// action disabled
$lightgray: #ececec;

// Headlines
// use on blue
$orange: #ffa500;
// use on sand/white
$darkorange: #ff8213;

// Secondary colors
$green-1: #d8e9c5;
$green-2: #c1ddaf;
$green-3: #abd084;
$green-4: #a7d54c;
$green-5: #55a546;
$green-6: #3f7717;

$brown-1: #f4e2cc;
$brown-2: #f2c492;
$brown-3: #d08c5d;
$brown-4: #a55a29;
$brown-5: #6f4023;

// Grayscale
$gray-1: #f8f8f8;
$gray-2: #ececec;
$gray-3: #cacaca;
$gray-4: #999999;
$gray-5: #767676;
$gray-6: #5c5c5c;
$gray-7: #424242;
$gray-8: #1c1c1c;

// Function colors
$red-1: #e60000;
$red-2: #ff3b3b;

/* You can add global styles to this file, and also import other style files */
@use 'colors';
@use 'theme';
@import '@progress/kendo-theme-fluent/dist/all.css';

body {
  margin: 0;
  box-sizing: border-box;

  font-family: var(--kendo-font-family);
  font-size: var(--kendo-font-size);
  font-weight: 400;
  line-height: var(--line-height-m);
  color: colors.$gray-7;
}

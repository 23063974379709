@use 'colors';
@use 'fonts';
@use 'icons';
@use 'logos';
@use 'layout';

body {
  // Default Values
  --line-height-m: 1.4285714286;
  --line-height-l: 2;
  --font-size: 16px;
  --font-family: 'Marselis', Arial, sans-serif;
  --border-color: rgba(0, 0, 0, 0.08);
  --border-hover-color: rgba(0, 0, 0, 0.16);
  --primary: #{colors.$darkblue};
  --secondary: #{colors.$cyan-2};
  --error: #{colors.$red-1};

  // Font
  --kendo-font-family: var(--font-family);
  --kendo-font-size: var(--font-size);
  --kendo-enable-typography: true;
  --kendo-line-height: var(--line-height-l);
  --kendo-component-text: #{colors.$gray-7};

  // Coloring
  --kendo-primary: var(--primary);
  --kendo-secondary: var(--secondary);
  --kendo-invalid-border: var(--error);
  --kendo-invalid-text: var(--error);

  // Forms
  --kendo-form-text: #{colors.$gray-7};
  --kendo-form-hint-font-style: italic;
  --kendo-form-line-height: var(--line-height-m);
  --kendo-form-label-margin-bottom: 0px;
  --kendo-forms-invalid-color: var(--error);

  // Input
  --input-text-color: var(--primary);
  --kendo-input-border-width: 2px;
  --kendo-input-padding-x: 16px;
  --kendo-input-padding-y: 4px;
  --kendo-input-solid-text: var(--input-text-color);
  --kendo-input-solid-border: var(--border-color);
  --kendo-input-solid-hover-text: var(--input-text-color);
  --kendo-input-solid-hover-border: var(--border-hover-color);
  --kendo-input-solid-focus-text: var(--input-text-color);
  --kendo-input-solid-focus-border: var(--primary);
  --kendo-input-solid-hover-focus-text: var(--input-text-color);
  --kendo-input-solid-hover-focus-border: var(--primary);
  --kendo-input-focus-border-width: 0px;

  // Button
  --button-text-color: var(--primary);
  --button-solid-bg-color: var(--secondary);
  --button-solid-hover-bg-color: #{colors.$cyan-3};
  --button-flat-bg-color: transparent;
  --button-flat-hover-color: #{colors.$gray-2};
  --kendo-button-md-padding-x: 8px;
  --kendo-button-md-padding-y: 4px;
  --kendo-border-radius-lg: 4px;
  --kendo-border-radius-md: 2px;
  --kendo-button-spacing: 4px;
  --kendo-button-md-line-height: var(--line-height-m);
  --kendo-button-font-weight: 400;
  --kendo-button-solid-base-focus-outline: transparent;
  --kendo-button-solid-secondary-focus-outline: transparent;

  // Solid Button Colors
  --kendo-button-solid-secondary-text: var(--button-text-color);
  --kendo-button-solid-secondary-bg: var(--button-solid-bg-color);
  --kendo-button-solid-secondary-border: var(--button-solid-bg-color);
  --kendo-button-solid-secondary-focus-text: var(--button-text-color);
  --kendo-button-solid-secondary-focus-bg: var(--button-solid-bg-color);
  --kendo-button-solid-secondary-focus-border: var(--button-solid-bg-color);
  --kendo-button-solid-secondary-hover-text: var(--button-text-color);
  --kendo-button-solid-secondary-hover-bg: var(--button-solid-hover-bg-color);
  --kendo-button-solid-secondary-hover-border: var(--button-solid-hover-bg-color);
  --kendo-button-solid-secondary-active-text: var(--button-text-color);
  --kendo-button-solid-secondary-active-bg: var(--button-solid-bg-color);
  --kendo-button-solid-secondary-active-border: var(--button-solid-bg-color);
  --kendo-button-solid-secondary-disabled-text: #{colors.$gray-4};
  --kendo-button-solid-secondary-disabled-bg: #{colors.$gray-2};
  --kendo-button-solid-secondary-disabled-border: #{colors.$gray-2};
  --kendo-button-solid-secondary-active-hover-text: var(--button-text-color);
  --kendo-button-solid-secondary-active-hover-bg: var(--button-solid-hover-bg-color);
  --kendo-button-solid-secondary-active-hover-border: var(--button-solid-hover-bg-color);
  --kendo-button-solid-secondary-focus-hover-text: var(--button-text-color);
  --kendo-button-solid-secondary-focus-hover-bg: var(--button-solid-hover-bg-color);
  --kendo-button-solid-secondary-focus-hover-border: var(--button-solid-hover-bg-color);

  // Flat Button Colors
  --kendo-button-flat-primary-text: var(--button-text-color);
  --kendo-button-flat-primary-bg: var(--button-flat-bg-color);
  --kendo-button-flat-primary-border: var(--button-flat-bg-color);
  --kendo-button-flat-primary-focus-text: var(--button-text-color);
  --kendo-button-flat-primary-focus-bg: var(--button-flat-bg-color);
  --kendo-button-flat-primary-focus-border: var(--button-flat-bg-color);
  --kendo-button-flat-primary-hover-text: var(--button-text-color);
  --kendo-button-flat-primary-hover-bg: var(--button-flat-hover-color);
  --kendo-button-flat-primary-hover-border: var(--button-flat-hover-color);
  --kendo-button-flat-primary-active-text: var(--button-text-color);
  --kendo-button-flat-primary-active-bg: var(--button-flat-bg-color);
  --kendo-button-flat-primary-active-border: var(--button-flat-bg-color);
  --kendo-button-flat-primary-disabled-text: #{colors.$gray-4};
  --kendo-button-flat-primary-disabled-bg: #{colors.$gray-1};
  --kendo-button-flat-primary-disabled-border: #{colors.$gray-1};
  --kendo-button-flat-primary-active-hover-text: var(--button-text-color);
  --kendo-button-flat-primary-active-hover-bg: var(--button-flat-hover-color);
  --kendo-button-flat-primary-active-hover-border: var(--button-flat-hover-color);
  --kendo-button-flat-primary-focus-hover-text: var(--button-text-color);
  --kendo-button-flat-primary-focus-hover-bg: var(--button-flat-hover-color);
  --kendo-button-flat-primary-focus-hover-border: var(--button-flat-hover-color);

  // Popup
  --kendo-popup-border: var(--border-color);
  --kendo-popup-border-width: 1px;
  --kendo-popup-border-radius: 4px;
  --kendo-popup-line-height: var(--line-height-m);

  // Calendar
  --calendar-cell-size: 32px;
  --calendar-selected-cell-text-color: #{colors.$white};
  --calendar-selected-cell-bg-color: var(--primary);
  --kendo-calendar-border: var(--border-color);
  --kendo-infinite-calendar-view-padding-x: 16px;
  --kendo-infinite-calendar-view-padding-y: 0px;
  --kendo-infinite-calendar-header-padding-x: 16px;
  --kendo-infinite-calendar-header-padding-y: 8px;
  --kendo-calendar-nav-gap: 4px;
  --kendo-calendar-header-cell-height: var(--calendar-cell-size);
  --kendo-calendar-header-cell-width: var(--calendar-cell-size);
  --kendo-calendar-header-cell-text: #666666;
  --kendo-calendar-caption-height: var(--calendar-cell-size);
  --kendo-calendar-caption-padding-x: 8px;
  --kendo-calendar-caption-padding-y: 4px;
  --kendo-calendar-caption-font-size: var(--font-size);
  --kendo-calendar-caption-line-height: var(--line-height-m);
  --kendo-calendar-caption-color: #666666;
  --kendo-calendar-md-month-cell-size: var(--calendar-cell-size);
  --kendo-calendar-md-cell-font-size: var(--font-size);
  --kendo-calendar-cell-link-text: #{colors.$gray-7};
  --kendo-calendar-cell-focus-shadow: inset 0 0 0 2px var(--border-color);
  --kendo-calendar-cell-selected-bg: var(--calendar-selected-cell-bg-color);
  --kendo-calendar-cell-selected-text: var(--calendar-selected-cell-text-color);
  --kendo-calendar-cell-selected-hover-bg: var(--calendar-selected-cell-bg-color);
  --kendo-calendar-cell-selected-hover-text: var(--calendar-selected-cell-text-color);
  --kendo-calendar-today-border-radius: 4px;
  --kendo-calendar-today-bg: transparent;
  --kendo-calendar-today-text: #{colors.$gray-7};
  --kendo-calendar-today-hover-bg: #f0f0f0;
  --kendo-calendar-today-hover-text: #{colors.$gray-7};

  // Appbar
  --kendo-appbar-line-height: var(--line-height-m);

  // Picker
  --picker-focus-border-color: var(--primary);
  --kendo-picker-solid-border: var(--border-color);
  --kendo-picker-solid-hover-border: var(--border-hover-color);
  --kendo-picker-solid-hover-focus-border: var(--picker-focus-border-color);
  --kendo-picker-solid-focus-border: var(--picker-focus-border-color);

  // Checkbox
  --checkbox-color: var(--primary);
  --kendo-checkbox-border-width: 2px;
  --kendo-checkbox-checked-bg: var(--checkbox-color);
  --kendo-checkbox-checked-border: var(--checkbox-color);
  --kendo-checkbox-hover-checked-bg: var(--checkbox-color);
  --kendo-checkbox-hover-checked-border: var(--checkbox-color);
  --kendo-checkbox-indeterminate-text: var(--checkbox-color);
  --kendo-checkbox-indeterminate-border: var(--checkbox-color);
  --kendo-checkbox-hover-indeterminate-text: var(--checkbox-color);
  --kendo-checkbox-hover-indeterminate-border: var(--checkbox-color);

  // Expandable
  --accordion-border-color: #{colors.$gray-3};
  --accordion-expanded-color: #{colors.$gray-2};
  --kendo-expander-text: var(--primary);
}

// Combobox Button
.k-button {
  transition: color 0.2s ease-in-out;

  &-solid-base {
    border: none !important;
    background: none !important;
  }
}

// Datepicker / Calendar
.k-i-calendar::before {
  font-family: 'RuVIcon';
  font-weight: 500;
  font-size: 24px;
  color: var(--primary);
  content: '\e94e' !important;
}

.k-calendar-infinite .k-calendar-view {
  height: 288px;
  inline-size: 256px;
  min-height: 224px;
}

.k-calendar-md {
  font-size: var(--font-size);
  line-height: var(--line-height-m);
}

.k-calendar-table {
  margin-block: 0px;
  margin-inline: 16px;
}

.k-calendar-caption,
.k-calendar-caption.k-calendar-th,
.k-calendar .k-meta-header,
.k-calendar .k-month-header {
  text-align: left;
}

.k-month-calendar .k-calendar-td,
.k-calendar-monthview .k-calendar-td {
  width: var(--calendar-cell-size);
  height: var(--calendar-cell-size);
}

// Input
.k-input-inner {
  padding-inline: var(--kendo-input-padding-x);
  padding-block: var(--kendo-input-padding-y);
}

.k-input-button {
  padding: 4px;
  width: calc(1.4285714286em + 8px) !important;
  border-radius: 0px;
}

.k-icon-wrapper-host {
  display: block;
}

// Paragraph
.k-paragraph,
p {
  line-height: 20px;
  margin: 0 0 16px;
}

// Label
label {
  color: var(--primary);
}

// Card
.card {
  background-color: colors.$sand !important;
}

// Header

h2 {
  color: var(--primary);
}

// Links
a,
a:hover,
a:focus,
a:active {
  font-weight: bold;
  text-decoration: none;
  color: var(--primary);
  cursor: pointer;
}

a.underline {
  text-decoration: underline;
}

// Text

.heading {
  font-weight: bold;
  color: var(--primary);
  margin: 0;

  &--highlight {
    color: colors.$darkorange !important;
  }
}

.text {
  color: var(--primary);

  &--center {
    text-align: center !important;
  }

  &--bold {
    font-weight: bold !important;
  }

  &--block {
    text-align: justify !important;
  }

  &--highlighted {
    color: var(--highlighted-font-color) !important;
  }

  &--normalize {
    font-size: inherit;
    font-style: inherit;
  }
}

.description {
  font-size: 12px;
  color: colors.$gray-4;
}

.title {
  font-size: 18px;
  font-weight: bold;
  padding: 1rem 0;
  color: var(--primary);
}

.welcome {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  text-align: center;
  font-weight: bold;
  font-size: 22px;
  line-height: var(--line-height-m);
  color: var(--primary);
  padding-block: 3rem 1rem;

  &--left {
    @extend .welcome;
    justify-content: flex-start;
  }
}

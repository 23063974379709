// Condor color scheme - primary colors
$condor-darkblue: #003b75;
$condor-lightblue: #00529f;
$condor-yellow: #f9b200;

// Condor color scheme - secondary colors
$condor-violet: #764994;
$condor-purple: #c5bfdc;
$condor-gray: #8c94a1;
$condor-beige: #baaf95;
$condor-dargyellow: #976e00;
$condor-darkorange: #ce6f05;
$condor-lightgreen: #97a719;
$condor-green: #007233;
$condor-turquoise: #50908d;

// misc
$condor-button-hover-bg: #fbc540;

body[theme='condor'] {
  --font-family: Arial, sans-serif;
  --primary: #{$condor-darkblue};
  --secondary: #{$condor-yellow};

  --kendo-border-radius-lg: 0px;
  --kendo-border-radius-md: 0px;
  --button-solid-hover-bg-color: #{$condor-button-hover-bg};
  --kendo-button-solid-secondary-active-hover-bg: #{$condor-button-hover-bg};
  --kendo-button-solid-secondary-active-hover-border: #{$condor-button-hover-bg};

  .welcome {
    font-size: 36px;
  }
}
